.footer {
    margin-top: 4rem;
    padding: 4rem 0 2rem;
    background: #3a47d5;
    color: $white;
}

.footer-logo {
    display: block;
    margin-top: -15px;

    svg {
        fill: $white;
        width: 100%;
        height: 50px;
    }
}

.footer-copy {
    margin-top: 1rem;
}

.footer-nav {
    @include media-breakpoint-down(md) {
        margin-top: 2rem;
    }

    a:link,
    a:active,
    a:visited {
        color: $white;
    }
    a:hover {
        text-decoration: underline;
    }
}
