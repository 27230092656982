.hero {
    color: $white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    @include media-breakpoint-down(md) {
        margin: -2rem -1rem 2em;
        border-radius: 0;
        padding: 1rem 1rem;
    }

    @include media-breakpoint-up(md) {
        padding: 1rem 2rem 3rem;
    }
}

.hero-title {
    font-size: 14vw;

    @include media-breakpoint-up(md) {
        font-size: 5rem;
    }
}

.hero-title-small {
    font-size: 8vw;

    @include media-breakpoint-up(md) {
        font-size: 3.5rem;
    }
}

.hero-img img {
    max-width: 100%;
}

.hero-img-phone img {
    max-height: 200px;
    margin: 1rem auto -1rem;
    display: block;

    @include media-breakpoint-up(md) {
        max-height: 100%;
        margin: 0 auto -3rem;
    }
}

.jumbotron {
    margin-bottom: 2rem;
}