.colorcard {
    text-align: center;
    overflow: hidden;
    border: 0;

    color: $white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    .card-img-overlay,
    .card-body {
        //padding: 3rem;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .card-img-overlay {
        background: rgba(0, 0, 0, 0.4);
    }

    .card-img-peek {
        margin-bottom: -3rem;
    }
}

.colorcard-bg {
    background-position: center center;
    background-size: cover;
    padding-bottom: $spacer * 4;
    padding-top: $spacer * 4;
    position: relative;

    &::after {
        background: rgba(0, 0, 0, 0.3);
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .card-body {
        z-index: 1;
    }
}
