.downloads {
    padding: $spacer / 2 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }
}

.downloads > *:not(:first-child) {
    margin: 1rem 0 0 0;

    @include media-breakpoint-up(md) {
        margin: 0 0 0 1rem;
    }
}

.download-feature {
    display: block;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 5px;
}

.downloads-googlePlay,
.downloads-appStore {
    display: block;

    @include media-breakpoint-up(md) {
        display: inline-block;
    }

    img {
        @include media-breakpoint-up(md) {
            height: 50px;
        }

        @include media-breakpoint-down(sm) {
            width: 400px;
            max-width: 100%;
        }
    }
}