.sitenav {
    margin-bottom: 4rem;
    align-items: flex-end;

    .navbar-brand {
        max-width: 50%;

        @include media-breakpoint-up(md) {
            max-width: 300px;
        }

        svg {
            width: 100%;
            height: 65px;
            fill: $white;
        }
    }

    .nav-link {
        font-weight: 600;
        font-size: 2rem;
        color: $white;
    }
}

.sitenav-nav {
    @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        background: #551ee9;
        background: radial-gradient(circle at 80% 40%, rgba(#00c4ff, 0.95) 0%, rgba(#551ee9, 0.95) 90%);
    }
}

.sitenav-nav .nav-link {
    @include media-breakpoint-down(md) {
        font-size: 11vw;
    }
    @include media-breakpoint-up(md) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.sitenav-toggler {
    position: absolute;
    top: 1rem;
    right: 0rem;

    z-index: 15;

    .close {
        display: block;

        font-size: 5.5rem;
        font-weight: 100;
        line-height: 0.4;
        color: #fff;
        opacity: 0.8;
    }

    .navbar-toggler-icon {
        display: none;
        width: 2.3em;
        height: 2.3em;
    }

    &.collapsed {
        .close {
            display: none;
        }
        .navbar-toggler-icon {
            display: block;
        }
    }
}
