.simpleFreeCell-card .card-body {
    padding-bottom: 300px;
    background: url("/img/home/simpleFreeCell-cards.png") bottom center no-repeat;
    background-size: auto 280px;

    @include media-breakpoint-up(md) {
        background-size: 40% auto;
        padding-bottom: $card-spacer-y;
        background-position: bottom right;
    }
}

.solitaire-card .card-body {
    padding-bottom: 300px;
    background: url("/img/home/solitaire.png") bottom center no-repeat;
    background-size: auto 280px;

    @include media-breakpoint-up(md) {
        //background-size: 40% auto;
        padding-bottom: $card-spacer-y;
        background-position: 95% 100%;
    }
}