@use "sass:list";

.swatch {
    @each $name, $colors in $swatches {
        $color1: list.nth($colors, 1);
        $color2: list.nth($colors, 2);

        &--#{$name} {
            background: $color1;
            background: radial-gradient(ellipse at 90% 40%, $color1 -4%, $color2 100%);
        }
    }
}
