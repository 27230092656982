.maincontent {
    @include media-breakpoint-down(md) {
        margin: -2rem -1rem 0;
        border-radius: 0;
        padding: 2rem 1rem;
    }

    @include media-breakpoint-up(md) {
        padding: 3rem 4rem 3rem;
    }
}
